import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import ru from "element-plus/dist/locale/ru.min.mjs";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { VueSignalR } from "@dreamonkey/vue-signalr";
import { HubConnectionBuilder } from "@microsoft/signalr";
import router from "./router";
import { createPinia } from "pinia";
import cronElementPlus from "@vue-js-cron/element-plus";
import "@vue-js-cron/element-plus/dist/element-plus.css";

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

const connection = new HubConnectionBuilder()
  .withUrl("/api/accesshub")
  .build();

const pinia = createPinia();

app
  .use(router)
  .use(pinia)
  .use(ElementPlus, { locale: ru })
  .use(cronElementPlus)
  .use(VueSignalR, { connection })
  .mount("#app");
