import axios from "axios";
import { handleResponseError, setAuthorizationHeader } from "./interceptors";

const configure = {
  baseURL: "/api",
};

const api = axios.create(configure);

api.interceptors.request.use((request) => {
  return setAuthorizationHeader(request);
});

api.interceptors.response.use(undefined, async (error) => {
  return handleResponseError(api, error);
});

export default api;
