import api from "@/api";
import { convertDataToFormParams } from "../convertDataToFormParams";

export const getLastParsingData = () => {
  return api.get("last-parsing");
};

export const postToLaunchParser = () => {
  return api.post("launch");
};

export const getParsedProfiles = ({ status, page, itemsPerPage, query }) => {
  const data = convertDataToFormParams({
    status,
    page,
    itemsPerPage,
    query,
  });
  return api.get(`profiles?${data}`);
};

export const getParserTrigger = () => {
  return api.get("parser-trigger");
};

export const postParserTrigger = ({ cron, startImmediately = false }) => {
  return api.post("parser-trigger", { cron, startImmediately });
};

export const toggleThreadSearch = (threadId) => {
  return api.post(`profiles/${threadId}/toggle-searching`);
};

export const getFinishedParsingJobs = ({ page, itemsPerPage }) => {
  const data = convertDataToFormParams({
    page,
    itemsPerPage,
  });
  return api.get(`finished-parsing-jobs?${data}`);
};

export const getForumsList = () => {
  return api.get("forums");
};

export const postForums = (forum) => {
  return api.post("forums", forum);
};

export const deleteForum = (id) => {
  return api.delete(`forums/${id}`);
};

export const updateForum = (forum) => {
  return api.post(`forums/${forum.id}`, forum);
};
