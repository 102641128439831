import axios from "axios";
import { convertDataToFormParams } from "@/api/convertDataToFormParams";
import {
  checkCannotConnectToServer,
  onConnectionError,
} from "../interceptors";

const configure = {
  baseURL: "/auth",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Allows: "application/x-www-form-urlencoded",
  },
};

const commonSettings = {
  client_id: "liza_spa",
  client_secret: "secret",
  grant_type: "password",
  scope: "openid profile offline_access liza.user",
};

const api = axios.create(configure);

api.interceptors.response.use(undefined, async (error) => {
  if (checkCannotConnectToServer(error)) {
    onConnectionError();
  }

  return Promise.reject(error);
});

const loginUser = ({ username, password }) => {
  const data = convertDataToFormParams({
    client_id: commonSettings.client_id,
    client_secret: commonSettings.client_secret,
    grant_type: commonSettings.grant_type,
    scope: commonSettings.scope,
    username,
    password,
  });

  return api.post("/connect/token", data);
};

const logoutUser = ({ access }) => {
  const data = convertDataToFormParams({
    client_id: commonSettings.client_id,
    client_secret: commonSettings.client_secret,
    token: access,
  });

  return api.post("/connect/revocation", data);
};

const refreshToken = ({ refresh }) => {
  const data = convertDataToFormParams({
    client_id: commonSettings.client_id,
    client_secret: commonSettings.client_secret,
    grant_type: "refresh_token",
    scope: commonSettings.scope,
    refresh_token: refresh,
  });

  return api.post("/connect/token", data);
};
export { loginUser, refreshToken, logoutUser };
