import { UserApi } from "@/api/services";
import { useUserStore } from "@/stores/user";

const setAuthorizationHeader = (request) => {
  const { accessToken } = useUserStore();

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
};

const checkTokenRefreshIsNeeded = ({ response, config }) => {
  return response && response.status === 401 && config && !config.__isRetry;
};

export const checkCannotConnectToServer = (error) => {
  return (
    error.code === "ERR_NETWORK" ||
    (error.response && error.response.status >= 500)
  );
};

export const onConnectionError = () => {};

const handleResponseError = async (api, error) => {
  if (checkCannotConnectToServer(error)) {
    onConnectionError();
    return Promise.reject(error);
  }

  if (!checkTokenRefreshIsNeeded(error)) {
    return Promise.reject(error);
  }

  error.config.__isRetry = true;

  try {
    await handleRefresh();
  } catch {
    const { logoutUser, refreshToken } = useUserStore();

    if (refreshToken) {
      logoutUser();
    }

    return Promise.reject(error);
  }

  return api(error.config);
};

let refreshTokenPromise = null;

const handleRefresh = async () => {
  const { refreshToken, saveAccessToken, saveRefreshToken } = useUserStore();

  if (!refreshToken) {
    throw new Error("No refresh token");
  }

  if (refreshTokenPromise) {
    return refreshTokenPromise;
  }

  try {
    refreshTokenPromise = UserApi.refreshToken({
      refresh: refreshToken,
    });

    const {
      data: { access_token, refresh_token },
    } = await refreshTokenPromise;

    saveAccessToken(access_token);
    saveRefreshToken(refresh_token);
  } catch (error) {
    throw new Error(error);
  } finally {
    refreshTokenPromise = null;
  }

  return refreshTokenPromise;
};

export { setAuthorizationHeader, handleResponseError };
