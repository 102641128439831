import { useUserStore } from "@/stores/user";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/views/Home"),
    name: "Home",
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    component: () => import("@/views/Login"),
    name: "Login",
  },
  {
    path: "/profiles",
    component: () => import("@/views/Profiles"),
    name: "Profiles",
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    component: () => import("@/views/Restarts"),
    name: "Settings",
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
  routes,
});

router.beforeEach((to) => {
  if (!to.meta.requiresAuth) {
    return true;
  }

  const { isUserLoggedIn } = useUserStore();

  if (isUserLoggedIn) {
    return true;
  }

  return {
    name: "Login",
    query: {
      redirectTo: to.fullPath,
    },
  };
});

export default router;
