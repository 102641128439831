import { defineStore } from "pinia";
import { UserApi } from "@/api/services";
import router from "@/router";

const tokenStorageKeys = {
  access: "liza_alert_access_token",
  refresh: "liza_alert_refresh_token",
};

export const useUserStore = defineStore("user", {
  state: () => ({
    accessToken: localStorage.getItem(tokenStorageKeys.access) || "",
    refreshToken: localStorage.getItem(tokenStorageKeys.refresh) || "",
  }),
  getters: {
    isUserLoggedIn(state) {
      return Boolean(state.accessToken);
    },
  },
  actions: {
    async loginUser({ username, password }) {
      const {
        data: { access_token, refresh_token },
      } = await UserApi.loginUser({ username, password });

      this.saveAccessToken(access_token);
      this.saveRefreshToken(refresh_token);
    },
    saveAccessToken(token) {
      this.accessToken = token;
      localStorage.setItem(tokenStorageKeys.access, token);
    },
    saveRefreshToken(token) {
      this.refreshToken = token;
      localStorage.setItem(tokenStorageKeys.refresh, token);
    },
    async logoutUser() {
      try {
        await UserApi.logoutUser({ access: this.accessToken });
        this.accessToken = "";
        this.refreshToken = "";
        localStorage.removeItem(tokenStorageKeys.access);
        localStorage.removeItem(tokenStorageKeys.refresh);
        router.push({ name: "Login" });
      } catch (error) {
        console.log(error);
      }
    },
  },
});
